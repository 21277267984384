import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import SpinnerButton from 'components/Buttons/SpinnerButton';
import SelectSubState from '../../../components/Select/SelectSubState';
import SelectJobType from '../../../components/Select/SelectJobType';
import { CUSTOM_INPUT_STYLES, CUSTOM_SELECT_STYLES } from '../../../components/StylesConstants';

function ClusterFilersExtended({
  filterCallback,
  isLoading,
  labelers,
  reviewers,
  prevSelected,
  messageCSVExtended,
  setMessageCSVExtended
}) {
  const [state, setState] = useState({
    searchClusterId: ''
  });
  const [selectedJobType, setSelectedJobType] = useState(null);
  const [selectedReviewer, setSelectedReviewer] = useState(null);
  const [selectedLabeler, setSelectedLabeler] = useState(null);
  const [selectedSubState, setSelectedSubState] = useState(null);

  useEffect(() => {
    if (prevSelected) {
      setSelectedJobType(prevSelected.selectedJobType);
      setSelectedReviewer(prevSelected.selectedReviewer);
      setSelectedLabeler(prevSelected.selectedLabeler);
      setSelectedSubState(prevSelected.subState);
      setState({
        searchClusterId: prevSelected.searchClusterId
      });
    }
  }, [prevSelected]);

  useEffect(() => {
    setMessageCSVExtended('');
  }, [selectedLabeler, state, selectedJobType, selectedReviewer, selectedSubState]);

  const onClick = () => {
    filterCallback(
      {
        selectedLabeler,
        selectedJobType,
        searchClusterId: state.searchClusterId,
        selectedReviewer,
        subState: selectedSubState
      },
      'extended'
    );
  };

  const onClickCSV = () => {
    filterCallback(
      {
        selectedLabeler,
        selectedJobType,
        searchClusterId: state.searchClusterId,
        selectedReviewer,
        subState: selectedSubState,
        isCSVRequest: true
      },
      'extended'
    );
  };

  const setField = (field, value) => {
    setState((prev) => ({ ...prev, [field]: value }));
  };

  const isDisabled = isLoading || !(selectedJobType || state.searchClusterId);

  return (
    <Card className="pb-2">
      <Card.Body className="pl-3 pr-4 mt-n3">
        <Row>
          <SelectJobType
            selectedJobType={selectedJobType}
            setSelectedJobType={setSelectedJobType}
            isDisabled={isLoading}
            className="ml-3 mt-3"
            isClustering
          />
          <SelectSubState
            selectedSubState={selectedSubState}
            setSelectedSubState={setSelectedSubState}
            isDisabled={isLoading}
            className="ml-3 mt-3"
          />
          <Select
            value={selectedLabeler}
            styles={CUSTOM_SELECT_STYLES}
            onChange={setSelectedLabeler}
            options={labelers}
            isDisabled={isLoading}
            placeholder="Select Labelers"
            isSearchable
            className="ml-3 mt-3"
            required
            isClearable
          />
          <Select
            value={selectedReviewer}
            styles={CUSTOM_SELECT_STYLES}
            onChange={setSelectedReviewer}
            options={reviewers}
            isDisabled={isLoading}
            placeholder="Select Reviewers"
            isSearchable
            className="ml-3 mt-3"
            required
            isClearable
          />
          <div className="ml-3 mt-3">
            <Form.Control
              style={CUSTOM_INPUT_STYLES}
              disabled={isLoading}
              type="search"
              onChange={(event) => setField('searchClusterId', event.target.value)}
              className="form-control"
              placeholder="Search on C. ID"
              name="searchClusterId"
              value={state.searchClusterId}
            />
          </div>
          <div className="ml-3 mt-3">
            <SpinnerButton
              style={{ height: '50px' }}
              isLoading={isLoading}
              disabled={isDisabled}
              onClick={onClick}
              value="Search"
            />
          </div>
          <div className="ml-3 mt-3">
            <SpinnerButton
              className="btn"
              style={{ height: '50px' }}
              onClick={onClickCSV}
              isLoading={isLoading}
              disabled={isDisabled}
              value="Request CSV"
            />
          </div>
        </Row>
        {messageCSVExtended && <div className="mt-3">{messageCSVExtended}</div>}
      </Card.Body>
    </Card>
  );
}

ClusterFilersExtended.propTypes = {
  filterCallback: PropTypes.func,
  isLoading: PropTypes.bool,
  labelers: PropTypes.array,
  reviewers: PropTypes.array,
  prevSelected: PropTypes.object,
  messageCSVExtended: PropTypes.string,
  setMessageCSVExtended: PropTypes.func
};

ClusterFilersExtended.defaultProps = {
  filterCallback: () => {},
  isLoading: false,
  labelers: [],
  reviewers: [],
  prevSelected: {},
  messageCSVExtended: '',
  setMessageCSVExtended: () => {}
};

export default ClusterFilersExtended;
