import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Form, Row } from 'react-bootstrap';
import SpinnerButton from 'components/Buttons/SpinnerButton';
import CropProducts from '../../CropLabelingProcess/CropProducts';
import { useBatchLabelingContext } from '../../../contexts/BatchLabelingProcess';
import DialogModal from '../../../components/Modal/Modal';
import SelectState from '../../../components/Select/SelectState';
import SelectJobType from '../../../components/Select/SelectJobType';
import { CUSTOM_INPUT_STYLES } from '../../../components/StylesConstants';

function ModalSearch({ modalShow, setModalShow, selectedJobType, setField }) {
  const [selectedLabel, setSelectedLabel] = useState('');
  const [disableButton, setDisableButton] = useState(true);

  const handleClose = () => setModalShow(false);

  const onSelect = (el) => {
    setField('searchBB', el.id);
    setDisableButton(false);
  };

  return (
    <DialogModal
      title="Brand Bank search"
      size="lg"
      body={
        <div className="mt-n4">
          <CropProducts
            onChange={(selectedLabel) => setSelectedLabel(selectedLabel)}
            selectedLabel={selectedLabel}
            selectedJobType={selectedJobType}
            onSelect={onSelect}
          />
        </div>
      }
      handleClose={handleClose}
      handleApprove={handleClose}
      show={modalShow}
      approve="Use it"
      disabled={disableButton}
    />
  );
}

function ClusterFiltersBase({
  filterCallback,
  isLoading,
  prevSelected,
  messageCSVBase,
  setMessageCSVBase
}) {
  const [state, setState] = useState({
    searchBB: '',
    searchClusterId: ''
  });
  const [selectedJobType, setSelectedJobType] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedReviewer, setSelectedReviewer] = useState([]);
  const [selectedLabeler, setSelectedLabeler] = useState([]);
  const [validationError, setValidationError] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [isDisabledBBSearch, setIsDisabledBBSearch] = useState(true);

  const { clearSKU, handleJobTypeChange, clearSelectedJobType } = useBatchLabelingContext();

  useEffect(() => {
    if (prevSelected) {
      setSelectedJobType(prevSelected.selectedJobType);
      setSelectedState(prevSelected.selectedState);
      setSelectedReviewer(prevSelected.selectedReviewer);
      setSelectedLabeler(prevSelected.selectedLabeler);
      setState(prevSelected.state);
      setState({
        searchBB: prevSelected.searchBB,
        searchClusterId: prevSelected.searchClusterId
      });
    }
  }, [prevSelected]);

  useEffect(() => {
    setMessageCSVBase('');
  }, [selectedLabeler, state, selectedJobType, selectedState, selectedReviewer]);

  const onClick = () => {
    filterCallback(
      {
        selectedLabeler,
        searchBB: state.searchBB,
        selectedJobType,
        searchClusterId: state.searchClusterId,
        selectedState,
        selectedReviewer
      },
      'base'
    );
  };

  const onClickCSV = () => {
    filterCallback(
      {
        selectedLabeler,
        searchBB: state.searchBB,
        selectedJobType,
        searchClusterIdBase: state.searchClusterIdBase,
        selectedState,
        selectedReviewer,
        isCSVRequest: true
      },
      'base'
    );
  };

  const validateUUID = (uuid) => {
    const uuidRegex =
      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

    if (!uuid || uuidRegex.test(uuid)) {
      setValidationError('');
    } else {
      setValidationError('Invalid UUID format');
    }
  };

  const setField = (field, value) => {
    setState((prev) => ({ ...prev, [field]: value }));
    if (field === 'searchBB') validateUUID(value);
  };

  const isDisabled =
    isLoading || !!validationError || !(selectedJobType || state.searchBB || state.searchClusterId);

  const onClickJobType = (jobType) => {
    setSelectedJobType(jobType);
    clearSKU();
    if (jobType) {
      handleJobTypeChange(jobType.value);
      setIsDisabledBBSearch(false);
    } else {
      clearSelectedJobType();
      setSelectedJobType(null);
      setIsDisabledBBSearch(true);
    }
  };

  return (
    <Card className="pb-2">
      <Card.Body className="pl-3 pr-4 mt-n3">
        <Row>
          <SelectJobType
            selectedJobType={selectedJobType}
            setSelectedJobType={(el) => onClickJobType(el)}
            isDisabled={isLoading}
            className="ml-3 mt-3"
            isClustering
          />
          <SelectState
            selectedState={selectedState}
            setSelectedState={setSelectedState}
            isDisabled={isLoading}
            className="ml-3 mt-3"
          />
          <div style={{ height: '50px' }} className="ml-3 mt-3 text-nowrap">
            <div style={CUSTOM_INPUT_STYLES} className="d-inline-block">
              <Form.Control
                style={{ ...CUSTOM_INPUT_STYLES, borderRadius: '4px 0 0 4px' }}
                disabled={isLoading}
                type="search"
                onChange={(event) => setField('searchBB', event.target.value)}
                className="form-control"
                placeholder="Search on BB UUID"
                name="searchBB"
                isInvalid={!!validationError}
                value={state.searchBB}
              />
              <Form.Control.Feedback type="invalid">{validationError}</Form.Control.Feedback>
            </div>
            <Button
              onClick={() => setModalShow(true)}
              size="md"
              variant="defoult"
              disabled={isDisabledBBSearch || isLoading}
              style={{
                height: '100%',
                borderRadius: '0 4px 4px 0',
                marginLeft: '-2px',
                verticalAlign: 'top',
                padding: ' 4px 8px',
                lineHeight: 1.125
              }}
              title="Brand Bank search"
            >
              BBS
              <br />
              on label
            </Button>
          </div>
          <div className="ml-3 mt-3">
            <Form.Control
              style={CUSTOM_INPUT_STYLES}
              disabled={isLoading}
              type="search"
              onChange={(event) => setField('searchClusterId', event.target.value)}
              className="form-control"
              placeholder="Search on C. ID"
              name="searchClusterId"
              value={state.searchClusterId}
            />
          </div>
          <div className="ml-3 mt-3">
            <SpinnerButton
              style={{ height: '50px' }}
              isLoading={isLoading}
              disabled={isDisabled}
              onClick={onClick}
              value="Search"
            />
          </div>
          <div className="ml-3 mt-3">
            <SpinnerButton
              className="btn"
              style={{ height: '50px' }}
              onClick={onClickCSV}
              isLoading={isLoading}
              disabled={isDisabled}
              value="Request CSV"
            />
          </div>
        </Row>
        {messageCSVBase && <div className="mt-3">{messageCSVBase}</div>}
        <ModalSearch
          modalShow={modalShow}
          setModalShow={setModalShow}
          selectedJobType={selectedJobType}
          setField={setField}
        />
      </Card.Body>
    </Card>
  );
}

ClusterFiltersBase.propTypes = {
  filterCallback: PropTypes.func,
  isLoading: PropTypes.bool,
  prevSelected: PropTypes.object,
  messageCSVBase: PropTypes.string,
  setMessageCSVBase: PropTypes.func
};

ClusterFiltersBase.defaultProps = {
  filterCallback: () => {},
  isLoading: false,
  prevSelected: {},
  messageCSVBase: '',
  setMessageCSVBase: () => {}
};

export default ClusterFiltersBase;
