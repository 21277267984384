import React, { useEffect, useState } from 'react';
import { Accordion, Button, Col, Row, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleDown, faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useUserContext } from '../../../contexts/Users';
import { getAuditReportBBS } from '../../../api/auditReport';
import { PAGE_SIZE } from '../../../components/Paginators/ListPaginator';

function ClustersRow({
  item,
  index,
  handleCheckboxChange,
  showPreview,
  setUserInfoState,
  setClusterHistoryState,
  setGalleryCropsState,
  setShowSimilarState,
  setShowBBSearchState,
  onShareClick,
  selectedItems
}) {
  const { isAdmin } = useUserContext();
  const [label, setLabel] = useState('');

  useEffect(() => {
    if (!item.brandbank_uuid) {
      setLabel('Nothing found');
      return;
    }
    getAuditReportBBS({
      predefined_classes: item.predefined_classes,
      entity_id: item.brandbank_uuid,
      page: 1,
      page_size: PAGE_SIZE
    }).then(async (response) => {
      const status = await response.status;
      if (status === 200) {
        const data = await response.json();
        const newDataBBS = data.results;
        if (newDataBBS.length > 0) {
          setLabel(newDataBBS[0].name);
        } else {
          setLabel('Nothing found');
        }
      } else {
        setLabel('Nothing found');
      }
    });
  }, []);

  const styles = {
    odd: {
      background: '#fff'
    },
    even: {
      background: '#f2f2f2'
    },
    headerContainer: {
      position: 'relative',
      cursor: 'pointer',
      height: 24,
      margin: '-24px 0 0 auto',
      width: 24
    },
    topPlus: {
      position: 'absolute',
      right: 4,
      top: 4,
      zIndex: 1
    },
    topMinus: {
      position: 'absolute',
      right: 4,
      top: -20,
      zIndex: 2,
      background: 'inherit',
      pointerEvents: 'none'
    },
    wrapper: {
      position: 'relative'
    }
  };

  const clickShare = (item) => {
    onShareClick(
      {
        isShared: true,
        page: 1,
        searchClusterId: item.id,
        selectedJobType: {
          value: {
            predefined_classes_id: item.predefined_classes
          }
        }
      },
      'base'
    );
  };

  const isChecked = selectedItems.includes(item.id);

  return (
    <div
      style={index % 2 ? styles.odd : styles.even}
      className="d-flex p-3 align-items-center w-100"
    >
      <div className="pr-3">
        <input
          id={item.id}
          type="checkbox"
          name={item.id}
          onChange={() => handleCheckboxChange(item.id)}
        />
      </div>
      <div className="w-100">
        <Row>
          <Col sm="12" md="2">
            <div
              style={{
                border: ' 1px solid grey',
                maxHeight: '106px',
                maxWidth: '106px',
                boxSizing: 'border-box',
                position: 'relative'
              }}
              className="d-flex align-items-center justify-content-center h-100 w-100 p-2"
            >
              <div
                style={{ cursor: 'pointer', overflow: 'hidden' }}
                className="d-flex align-items-center justify-content-center h-100 w-100"
                onClick={() => showPreview(item.preview_image_path)}
              >
                <img
                  src={item.preview_image_path}
                  alt=""
                  style={{ maxHeight: '82px', maxWidth: '82px' }}
                />
              </div>
              {!!item.count_of_points && (
                <Button
                  className="ml-1"
                  onClick={() =>
                    setGalleryCropsState((prev) => ({
                      ...prev,
                      clusterId: item.id,
                      showModal: true
                    }))
                  }
                  size="sm"
                  variant="defoult"
                  style={{
                    right: 2,
                    bottom: 2,
                    position: 'absolute',
                    background: '#fff'
                  }}
                >
                  {item.count_of_points}
                </Button>
              )}
            </div>
          </Col>
          <Col md="7" sm="12">
            <span className="text-muted">cluster uuid: </span>
            {item.id ? (
              <>
                {item.id}
                <Button
                  className="ml-1"
                  onClick={() => clickShare(item)}
                  size="sm"
                  variant="defoult"
                  style={{ padding: '2px 4px' }}
                >
                  Share
                </Button>
              </>
            ) : (
              'unset'
            )}
            <br />
            <span className="text-muted">brandbank label: </span>
            {label || <Spinner size="sm" animation="border" role="status" />}
            <br />
            <span className="text-muted">brandbank UUID: </span>
            {item.brandbank_uuid ? (
                <>
                  {item.brandbank_uuid}
                  <Button
                      className="ml-1"
                      onClick={() =>
                          setShowBBSearchState({
                            item: item,
                            showModal: true
                          })
                      }
                      size="sm"
                      variant="defoult"
                      style={{ padding: '2px 4px' }}
                  >
                    BBS
                  </Button>
                </>
            ) : (
                'unset'
            )}
            <br />
            <span className="text-muted">pc UUID: </span>
            {item.predefined_classes || 'unset'}
          </Col>
          <Col md="3" sm="12">
            <span className="text-muted">state: </span>
            {item.state || 'unset'}
            <br />
            <span className="text-muted">substate: </span>
            {item.substate || 'unset'}
            <br />
            <span className="text-muted">labeler_id: </span>
            {item.labeler_id ? (
              <span>
                {isAdmin ? (
                  <Button
                    className="btn-simple btn-link p-0"
                    type="button"
                    variant="link"
                    onClick={() =>
                      setUserInfoState((prev) => ({
                        ...prev,
                        userId: item.labeler_id,
                        showModal: true
                      }))
                    }
                  >
                    {item.labeler_id}
                  </Button>
                ) : (
                  item.labeler_id
                )}
              </span>
            ) : (
              'unset'
            )}
            <div className="pr-4">
              <span className="text-muted">reviewer_id: </span>
              {item.reviewer_id ? (
                <span>
                  {isAdmin ? (
                    <Button
                      className="btn-simple btn-link p-0"
                      type="button"
                      variant="link"
                      onClick={() =>
                        setUserInfoState((prev) => ({
                          ...prev,
                          userId: item.reviewer_id,
                          showModal: true
                        }))
                      }
                    >
                      {item.reviewer_id}
                    </Button>
                  ) : (
                    item.reviewer_id
                  )}
                </span>
              ) : (
                'unset'
              )}
            </div>
          </Col>
        </Row>
        <Accordion>
          <Accordion.Toggle eventKey="0" as="div" style={styles.headerContainer}>
            <FontAwesomeIcon icon={faAngleDoubleDown} style={styles.topPlus} />
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0" style={styles.wrapper}>
            <div style={index % 2 ? styles.odd : styles.even}>
              <FontAwesomeIcon icon={faAngleDoubleUp} style={styles.topMinus} />
              <Row>
                <Col sm="12" md="2">
                  <Button
                    className="mt-1"
                    onClick={() =>
                      setShowSimilarState({
                        item: item,
                        showModal: true
                      })
                    }
                    size="sm"
                    variant="defoult"
                    style={{ padding: '2px', width: 'calc(50% - 2px)', maxWidth: 51 }}
                  >
                    Show
                    <br />
                    Similar
                  </Button>
                  <Button
                    className="ml-1 mt-1"
                    onClick={() =>
                      setClusterHistoryState((prev) => ({
                        ...prev,
                        clusterId: item.id,
                        showModal: true
                      }))
                    }
                    size="md"
                    variant="defoult"
                    style={{
                      paddingLeft: '2px',
                      paddingRight: '2px',
                      width: 'calc(50% - 2px)',
                      maxWidth: 51
                    }}
                  >
                    <i style={{ fontWeight: 'bold' }} className="fa fa-history" />
                  </Button>
                </Col>
                <Col md="7" sm="12">
                  <span className="text-muted">jobtype id: </span>
                  {item.job_type_id || 'unset'}
                  <br/>
                  <span className="text-muted">created_at: </span>
                  {item.created_at ? moment(item.created_at).format('YYYY-MM-DD HH:mm') : 'unset'}
                  <br />
                  <span className="text-muted">updated_at: </span>
                  {item.updated_at ? moment(item.updated_at).format('YYYY-MM-DD HH:mm') : 'unset'}
                </Col>
                <Col md="3" sm="12">
                  <span className="text-muted">fix_flag: </span>
                  {`${item.fix_flag}` || 'unset'}
                  <br />
                  <span className="text-muted">reject_flag: </span>
                  {`${item.reject_flag}` || 'unset'}
                </Col>
              </Row>
            </div>
          </Accordion.Collapse>
        </Accordion>
      </div>
      <div style={{ display: 'none' }}>
        id: {item.id || 'unset'}
        <br />
        points: {item.points || 'unset'}
        <br />
        predefined_classes: {item.predefined_classes || 'unset'}
        <br />
        priority: {item.priority || 'unset'}
        <br />
        project: {item.project || 'unset'}
      </div>
    </div>
  );
}

ClustersRow.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  handleCheckboxChange: PropTypes.func,
  showPreview: PropTypes.func,
  setUserInfoState: PropTypes.func,
  setClusterHistoryState: PropTypes.func,
  setGalleryCropsState: PropTypes.func,
  setShowSimilarState: PropTypes.func,
  setShowBBSearchState: PropTypes.func,
  onShareClick: PropTypes.func
};

ClustersRow.defaultProps = {
  item: {},
  index: 0,
  handleCheckboxChange: () => {},
  showPreview: () => {},
  setUserInfoState: () => {},
  setClusterHistoryState: () => {},
  setGalleryCropsState: () => {},
  setShowSimilarState: () => {},
  setShowBBSearchState: () => {},
  onShareClick: () => {}
};

export default ClustersRow;
