import React, { useState, useEffect } from 'react';
import { Col, Modal, Row, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { getLabelersJobTypes } from '../../../api/user';

function ShowUserInfo({ userInfoState, setUserInfoState }) {
  const [userInfo, setUserInfo] = useState(undefined);

  const getUserInfo = (id) => {
    getLabelersJobTypes(id).then(async (response) => {
      const status = await response.status;
      if (status === 200) {
        const data = await response.json();
        setUserInfo(data);
      }
    });
  };

  useEffect(() => {
    if (userInfoState.prevUserId !== userInfoState.userId) {
      setUserInfo(undefined);
      getUserInfo(userInfoState.userId);
    }
  }, [userInfoState.userId]);

  const handleClose = () => {
    setUserInfoState((prev) => ({ ...prev, showModal: false, prevUserId: userInfoState.userId }));
  };

  return (
    <Modal show={userInfoState.showModal} centered onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title as="h4" className="mt-0">User Info</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {userInfo ? (
          <Row>
            <Col xs={4}>ID:</Col>
            <Col xs={8}>{userInfo.user.id}</Col>
            <Col xs={4}>First name:</Col>
            <Col xs={8}>{userInfo.user.first_name || 'unset'}</Col>
            <Col xs={4}>Last name:</Col>
            <Col xs={8}>{userInfo.user.last_name || 'unset'}</Col>
            <Col xs={4}>Username:</Col>
            <Col xs={8}>{userInfo.user.username || 'unset'}</Col>
            <Col xs={4}>Mail:</Col>
            <Col xs={8}>{userInfo.user.email || 'unset'}</Col>
            <Col xs={4} className="pt-3">
              Permission:
            </Col>
            <Col xs={8} className="pt-3">
              {userInfo.permission || 'unset'}
            </Col>
            <Col xs={4}>State:</Col>
            <Col xs={8}>{userInfo.state || 'unset'}</Col>
            <Col xs={4} className="pt-3">
              Assigned station:
            </Col>
            <Col xs={8} className="pt-3">
              {userInfo.assigned_station || 'unset'}
            </Col>
            <Col xs={4}>Allowed job types:</Col>
            <Col xs={8}>[ {userInfo.allowed_job_types.join(', ')} ]</Col>
            <Col xs={4}>Allowed if config:</Col>
            <Col xs={8}>[ {userInfo.allowed_if_config.join(', ')} ]</Col>
          </Row>
        ) : (
          <Spinner size="sm" animation="border" role="status" />
        )}
      </Modal.Body>
    </Modal>
  );
}

ShowUserInfo.propTypes = {
  userInfoState: PropTypes.object,
  setUserInfoState: PropTypes.func
};
ShowUserInfo.defaultProps = {
  userInfoState: {},
  setUserInfoState: () => {}
};

export default ShowUserInfo;
