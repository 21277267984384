import React, { useState, useEffect } from 'react';
import { Alert, Button, ListGroup, Modal, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { PAGE_SIZE } from '../../../components/Paginators/ListPaginator';
import SingleSKUReferenceItem from '../../CropLabelingProcess/SingleSKUReferenceItem';
import { getAuditReportBBS } from '../../../api/auditReport';

function ShowBBSearch({ showBBSearchState, setShowBBSearchState, setSelectedBase }) {
  const { showModal, item } = showBBSearchState;
  const [resultBBS, setResultBBS] = useState([]);
  const [infoBBS, setInfoBBS] = useState({});
  const [selectedLabel, setSelectedLabel] = useState('');

  const handleClose = () => {
    setShowBBSearchState({
      showModal: false,
      item: undefined
    });
    setSelectedLabel('');
  };

  useEffect(() => {
    if (showModal) {
      setInfoBBS({ isLoading: true, error: false });
      setResultBBS([]);
      getAuditReportBBS({
        predefined_classes: item.predefined_classes,
        entity_id: item.brandbank_uuid,
        page: 1,
        page_size: PAGE_SIZE
      }).then(async (response) => {
        const status = await response.status;
        if (status === 200) {
          const data = await response.json();
          const newDataBBS = data.results;
          setResultBBS(newDataBBS);
          setInfoBBS({
            isLoading: false,
            error: false
          });
        } else {
          setInfoBBS({ isLoading: false, error: true });
        }
      });
    }
  }, [item]);

  const handleApprove = () => {
    setSelectedBase((prev) => ({
      ...prev,
      searchBB: item.brandbank_uuid
    }));
    handleClose();
  };

  return (
    <Modal size="lg" show={showModal} centered onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title as="h4" className="mt-0">Brand Bank Search for {item?.brandbank_uuid}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Modal.Body>
          {infoBBS?.isLoading ? (
            <Spinner size="lg" animation="border" role="status" />
          ) : (
            <>
              {infoBBS?.error && <div>Sorry, something went wrong</div>}
              {!infoBBS?.error && resultBBS.length === 0 && (
                <Alert variant="dark">Nothing found</Alert>
              )}
              {!infoBBS?.error && resultBBS.length > 0 && (
                <div>
                  {resultBBS.map((item) => (
                    <ListGroup.Item key={item.id}>
                      <SingleSKUReferenceItem
                        name={item.name}
                        selectedLabel={selectedLabel}
                        onClick={(selectedLabel) => setSelectedLabel(selectedLabel)}
                        photos={item.photos}
                      />
                    </ListGroup.Item>
                  ))}
                </div>
              )}
            </>
          )}
        </Modal.Body>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={!selectedLabel} variant="danger" onClick={handleApprove}>
          Use it
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ShowBBSearch.propTypes = {
  showBBSearchState: PropTypes.object,
  setShowBBSearchState: PropTypes.func,
  setSelectedBase: PropTypes.func
};

ShowBBSearch.defaultProps = {
  showBBSearchState: {},
  setShowBBSearchState: () => {},
  setSelectedBase: () => {}
};

export default ShowBBSearch;
